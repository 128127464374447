import { Grid, Typography, Avatar } from "@mui/material";
import AvatarImage from "./../../images/avatar.png";
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import { MusicCard } from "../../components/MusicCard";

const data = {
    music: [{
        cover: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/o%20come%20o%20come%20emmanuel%20cover.png?alt=media&token=9bcdc902-8a8a-46ad-b5e7-c14a793ec792",
        name: "O Come, O Come Emmanuel",
        link: "https://distrokid.com/hyperfollow/chapelmusic/o-come-o-come-emmanuel",
        audioSrc: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/O%20Come%20O%20Come%20Emmanuel%203.mp3?alt=media&token=2acb6d69-d646-4f5c-835d-aaecf6087a24"
    },{
        cover: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/Finish%20Line%20cover.png?alt=media&token=ddf93e2e-1fec-4742-b58b-5393734b2f3c",
        name: "Finish Line (Instrumental, Short)",
        link: "https://distrokid.com/hyperfollow/chapelmusic/finish-line-instrumental-short",
        audioSrc: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/Finish%20Line%20(Instrumental%2C%20Short).mp3?alt=media&token=2ad549a4-373e-42af-a9e1-ec42edc2f4b8"
    }, {
        cover: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/final%20frontier.png.jpeg?alt=media&token=7016bb2f-cd85-4bb9-9cc4-e14b498b2010",
        name: "Final Frontier (Instrumental)",
        link: "https://distrokid.com/hyperfollow/chapelmusic/final-frontier",
        audioSrc: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/Final%20frontier.mp3?alt=media&token=530ff6b8-535e-4179-9e8d-068d0b7435d6"
    }, {
        cover: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/so-will-i-cover.jpeg?alt=media&token=2e8c562d-edf8-4864-97f6-8d59e22b9072",
        name: "So Will I (Instrumental)",
        link: "https://distrokid.com/hyperfollow/chapelmusic/so-will-i-instrumental",
        audioSrc: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/So%20Will%20I%20(Instrumental).mp3?alt=media&token=46fa697b-c5c4-4de0-a593-edd07779074e"
    }, {
        cover: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/no-longer-cover.jpeg?alt=media&token=8c72d7f1-c371-4b8b-8d28-e215a6b8dbee",
        name: "No longer (Instrumental)",
        link: "https://distrokid.com/hyperfollow/chapelmusic/no-longer-instrumental",
        audioSrc: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/No%20longer%20(Instrumental).mp3?alt=media&token=5497baa3-178d-47ca-a204-53bd3313574c"
    }, {
        cover: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/no-longer-cover.jpeg?alt=media&token=8c72d7f1-c371-4b8b-8d28-e215a6b8dbee",
        name: "Я не буду рабом",
        link: "https://distrokid.com/hyperfollow/chapelmusic/----3",
        audioSrc: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/%D0%AF%20%D0%BD%D0%B5%20%D0%B1%D1%83%D0%B4%D1%83%20%D1%80%D0%B0%D0%B1%D0%BE%D0%BC%20%D0%B3%D1%80%D0%B5%D1%85%D0%B0%20(No%20longer%20slaves)%20v2.mp3?alt=media&token=a5f74a11-93b8-409d-9ef3-24e5e80c771e"
    }, {
        cover: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/alive-cover.jpeg?alt=media&token=329ac576-117d-4a61-a766-c226eae751b5",
        name: "Alive (Instrumental)",
        link: "https://distrokid.com/hyperfollow/chapelmusic/alive-instrumental",
        audioSrc: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/Alive%20(instrumental).mp3?alt=media&token=0c81d00d-e5a8-4b0f-928c-c4c0c088c63e"
    }, {
        cover: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/oceans-cover.jpg?alt=media&token=b23ebf24-e009-4e3f-a434-109ee2e9f4d3",
        name: "Oceans",
        link: "https://distrokid.com/hyperfollow/chapelmusic/oceans",
        audioSrc: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/Chapel%20Music%20-%20Oceans%20(Hillsong%20United%20Cover).mp3?alt=media&token=abccb6f9-3884-4521-a2ec-711d00185568"
    }, {
        cover: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/oceans-cover.jpg?alt=media&token=b23ebf24-e009-4e3f-a434-109ee2e9f4d3",
        name: "Oceans (Instrumental)",
        link: "https://distrokid.com/hyperfollow/chapelmusic/oceans-instrumental",
        audioSrc: "https://firebasestorage.googleapis.com/v0/b/vladbasin-me.appspot.com/o/Oceans%20Instrumental.mp3?alt=media&token=4c389b22-1573-497d-a0ee-3988030370e8"
    }]
}

export const LandingPage = () => {
    return (
        <Grid container alignContent="center" alignItems="center" direction="column" spacing={4}>
            <Grid item>
                <Avatar alt="Vlad Basin" src={AvatarImage} sx={{ width: 120, height: 120 }} />
            </Grid>
            <Grid item>
                <Typography variant="h4"><MusicNoteIcon fontSize="large" />MUSIC</Typography>
            </Grid>
            <Grid container item spacing={3} direction="column" alignItems="center">
                {data.music.map((item, index) => (
                    <Grid item>
                        <MusicCard key={index} {...item} />
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
}